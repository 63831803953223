import { toast } from 'react-toastify';
import { omitBy, isUndefined } from 'lodash-es';
import IconUrlMapping from 'constants/IconUrlMap';
import Lang from 'constants/Locale';
import { EntryCollection, FoodProvider, FoodProviderData, Localized, OrderOptionData } from 'types';
import FoodProviderRequester from 'context/requesters/foodProvider/foodProviderRequester';
import { PersistentStateRef, SortOptions, StateFilterOptions } from 'context/food-provider-context';
import Property, { NestedProperty } from 'types/property.type';
import Floor from 'types/floor.type';
import { LocalizedOrderService, NestedOrderService } from 'types/orderService.type';
import OrderMethod from 'types/orderMethod.type';
import UnitType, { NestedUnit } from 'types/unit.type';
import { GetItemsParams } from 'context/requesters/baseRequester';
import { DeepPartial } from 'types/global.type';
import Status, { NestedStatus } from 'constants/Status';
import { PlatformLinkData } from 'types/platformLink.type';
import { OrderOption } from 'types/orderOption.type';
import hash from './hash';

export const FOOD_PROVIDERS_PER_PAGE = 8;

export const isAllSelected = (properties: string[] | undefined) =>
  properties?.length === 1 && properties?.[0] === 'all';

export const handleAllSelection = (
  isAllCurrentlySelected: boolean,
  setProperties: (props: string[]) => void
) => {
  if (!isAllCurrentlySelected) {
    setProperties(['all']);
  }
};

export const handleIndividualSelection = (
  newKey: string,
  properties: string[],
  setProperties: (props: string[]) => void,
  propertyFilteringOptions: Record<string, string> | null
) => {
  if (properties.includes(newKey)) {
    // Remove from list
    const removedList = properties.filter((option) => option !== newKey);

    setProperties(removedList.length === 0 ? ['all'] : removedList);
  } else {
    // Add to list
    const addedList = [...properties, newKey];
    setProperties(
      addedList.length === Object.keys(propertyFilteringOptions ?? {}).length - 1
        ? ['all']
        : addedList
    );
  }
};

export const handleCheckboxOptionChange = (
  newKey: string,
  properties: string[],
  setProperties: (props: string[]) => void,
  propertyFilteringOptions: Record<string, string> | null
) => {
  const isAllCurrentlySelected = isAllSelected(properties);

  if (newKey === 'all') {
    handleAllSelection(isAllCurrentlySelected, setProperties);
  } else if (isAllCurrentlySelected) {
    setProperties([newKey]);
  } else {
    handleIndividualSelection(newKey, properties, setProperties, propertyFilteringOptions);
  }
};

type IconDimension = {
  width: string;
  height: string;
};

type IconDimensionKeys = Extract<
  keyof typeof IconUrlMapping,
  'add' | 'searchBlack' | 'save' | 'trash'
>;

type IconDimensionsMap = {
  [key in IconDimensionKeys]: IconDimension;
};

export const getIconDimensions = (isMobile: boolean): IconDimensionsMap => ({
  add: {
    width: isMobile ? '20' : '18',
    height: isMobile ? '20' : '18',
  },
  searchBlack: {
    width: '16',
    height: '16',
  },
  save: {
    width: isMobile ? '16' : '18',
    height: isMobile ? '16' : '18',
  },
  trash: {
    width: isMobile ? '18' : '20',
    height: isMobile ? '20' : '22',
  },
});

export const dateFormat = new Intl.DateTimeFormat('default', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});

interface HandleAvailableStoresProps {
  requester?: FoodProviderRequester;
  t: any;
  foodProviders: EntryCollection<FoodProvider> | null;
  propertyList: Property[] | null;
  unitList: UnitType[] | null;
  setAvailablePropertyOptions: React.Dispatch<React.SetStateAction<Record<string, string> | null>>;
  setAvailableUnits: React.Dispatch<React.SetStateAction<any[]>>;
  setPropertyFilteringOptions: React.Dispatch<React.SetStateAction<Record<string, string> | null>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Calculates and sets which properties can be available to filter
 * and which properties and units are available to create a new food provider
 */
export const handleAvailableStores = async ({
  requester,
  t,
  foodProviders,
  propertyList,
  unitList,
  setAvailablePropertyOptions,
  setAvailableUnits,
  setPropertyFilteringOptions,
  setIsLoading,
}: HandleAvailableStoresProps) => {
  if (requester && foodProviders?.items && foodProviders?.items?.length > 0) {
    setIsLoading?.(true);

    const properties =
      propertyList?.reduce((options: Record<string, string>, property) => {
        if (property.sys?.id) {
          options[property?.sys?.id] = property.webPropertyName;
        }
        return options;
      }, {} as Record<string, string>) ?? null;

    const propertiesWithAvailableUnits =
      unitList?.reduce((options: Record<string, string>, unit) => {
        if (unit?.property?.sys?.id) {
          options[unit?.property?.sys?.id] = unit?.property?.webPropertyName;
        }
        return options;
      }, {} as Record<string, string>) ?? null;

    setPropertyFilteringOptions({
      ['all']: t('food_provider_left_panel.filters.all_properties'),
      ...(properties ?? {}),
    });

    setAvailablePropertyOptions(
      !!propertiesWithAvailableUnits && Object.keys(propertiesWithAvailableUnits)?.length > 0
        ? propertiesWithAvailableUnits
        : null
    );
    setAvailableUnits(unitList && unitList?.length > 0 ? unitList : []);

    setIsLoading?.(false);
  }
};

export const fetchFloors = async (
  requester: FoodProviderRequester,
  foodProviders: EntryCollection<FoodProvider> | null,
  setFloors: React.Dispatch<React.SetStateAction<Floor[] | null>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester && foodProviders?.items && foodProviders?.items?.length > 0) {
    setIsLoading?.(true);
    try {
      const floors = await Promise.all<Floor>(
        foodProviders?.items?.reduce((accum, foodProvider) => {
          const floorId = foodProvider.unit?.[Lang.en]?.fields?.FNO?.[Lang.en]?.sys?.id;
          return floorId ? [...accum, requester.getFloorById(floorId)] : accum;
        }, [] as Promise<Floor>[])
      );

      setFloors(floors);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
    setIsLoading?.(false);
  }
};

export const getFloorFieldId = (foodProvider: DeepPartial<FoodProvider> | null) => {
  const floorField = foodProvider?.unit?.[Lang.en]?.fields?.FNO;
  const floorFieldId = floorField?.[Lang.en]?.sys?.id ?? null;

  return floorFieldId;
};

export const fetchFloorById = async (
  requester: FoodProviderRequester,
  selectedFoodProvider: DeepPartial<FoodProvider> | null,
  setSelectedFloor: React.Dispatch<React.SetStateAction<Floor | null>>,
  persistentStateRef: React.MutableRefObject<PersistentStateRef>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester) {
    try {
      const floorFieldId = getFloorFieldId(selectedFoodProvider);

      if (floorFieldId) {
        if (!persistentStateRef?.current) {
          persistentStateRef.current = { floorFieldId };
        } else {
          persistentStateRef.current = { ...persistentStateRef.current, floorFieldId };
        }
        requester.getFloorById(floorFieldId).then((floorData) => {
          setSelectedFloor(floorData);
          setIsLoading?.(false);
        });
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  }
};

export const fetchOrderMethods = async (
  requester: FoodProviderRequester,
  setAllOrderMethods: React.Dispatch<React.SetStateAction<OrderMethod[] | null>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester) {
    setIsLoading?.(true);
    try {
      const orderServices = await requester.getOrderMethods();
      setAllOrderMethods(orderServices?.items);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
    setIsLoading?.(false);
  }
};

export const fetchSelectedOrderOptions = async (
  requester: FoodProviderRequester,
  setSelectedOrderOptions: React.Dispatch<React.SetStateAction<any[]>>,
  persistentStateRef: React.MutableRefObject<PersistentStateRef>,
  orderOptionIds?: string[] | null,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester && orderOptionIds) {
    setIsLoading?.(true);
    const orderOptionIdsHash = hash(orderOptionIds);

    if (!persistentStateRef?.current) {
      persistentStateRef.current = { orderOptionIdsHash };
    } else {
      persistentStateRef.current = { ...persistentStateRef.current, orderOptionIdsHash };
    }
    try {
      const orderOptions = await Promise.all(
        orderOptionIds.map((orderOptionId) => requester.getOrderOptionById(orderOptionId))
      );

      setSelectedOrderOptions(orderOptions);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  } else {
    setSelectedOrderOptions([]);
  }
  setIsLoading?.(false);
};

export const fetchProperties = async (
  requester: FoodProviderRequester,
  setAllProperties: React.Dispatch<React.SetStateAction<Property[] | null>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester) {
    setIsLoading?.(true);
    try {
      const allProperties = await requester.getProperties(true);

      setAllProperties(allProperties);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
    setIsLoading?.(false);
  }
};

export const fetchOrderServices = async (
  requester: FoodProviderRequester,
  setAllOrderServices: React.Dispatch<React.SetStateAction<LocalizedOrderService[] | null>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester) {
    setIsLoading?.(true);
    try {
      const orderServices = await requester.getOrderServices();
      setAllOrderServices(orderServices?.items);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
    setIsLoading?.(false);
  }
};

export const fetchUnits = async (
  requester: FoodProviderRequester,
  setAllUnits: React.Dispatch<React.SetStateAction<UnitType[] | null>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  if (requester) {
    setIsLoading?.(true);
    try {
      const allUnits = await requester.getUnits();

      setAllUnits(allUnits);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
    setIsLoading?.(false);
  }
};

export const filterSelectedOrderServices = async (
  allOrderServices: LocalizedOrderService[] | null,
  selectedOrderOptions: any[],
  setSelectedOrderServices: React.Dispatch<React.SetStateAction<any[]>>,
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
) => {
  setIsLoading?.(true);
  if (allOrderServices && selectedOrderOptions) {
    const filteredOrderServices = allOrderServices?.filter((orderService) => {
      return (
        orderService?.sys?.id &&
        selectedOrderOptions?.some(
          (orderOption) =>
            orderOption?.orderService?.[Lang.en]?.sys?.id &&
            orderService?.sys?.id === orderOption?.orderService?.[Lang.en]?.sys?.id
        )
      );
    });

    setSelectedOrderServices(filteredOrderServices ?? []);
  }
  setIsLoading?.(false);
};

export interface FetchFoodProvidersProps {
  requester: FoodProviderRequester;
  appliedState: StateFilterOptions;
  currentPage: number;
  searchValue: string;
  appliedSort: SortOptions;
  appliedProperties: string[];
  setFoodProviders: React.Dispatch<React.SetStateAction<EntryCollection<FoodProvider> | null>>;
  setSelectedFoodProvider: React.Dispatch<React.SetStateAction<DeepPartial<FoodProvider> | null>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
}

export const fetchFoodProviders = async ({
  requester,
  appliedState,
  currentPage,
  searchValue,
  appliedSort,
  appliedProperties,
  setFoodProviders,
  setSelectedFoodProvider,
  setIsLoading,
  isMobile,
}: FetchFoodProvidersProps) => {
  if (requester) {
    setIsLoading?.(true);

    try {
      const params: Omit<GetItemsParams, 'path'> = {
        status: appliedState !== StateFilterOptions.All ? appliedState : undefined,
        skip: FOOD_PROVIDERS_PER_PAGE * (currentPage - 1),
        limit: FOOD_PROVIDERS_PER_PAGE,
        search: searchValue,
        sort: appliedSort === SortOptions.RecentActivity ? 'recent' : appliedSort,
      };

      if (appliedProperties.length > 0 && appliedProperties[0] !== 'all') {
        params.properties = appliedProperties.join(',');
      }

      const data = await requester.getItems(params);

      if (data) {
        setFoodProviders(data);

        await selectFirstItem(
          data.items?.length > 0,
          requester,
          setSelectedFoodProvider,
          data.items[0]?.sys?.id,
          isMobile
        );
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }

    setIsLoading?.(false);
  }
};

const selectFirstItem = async (
  hasItems: boolean,
  requester: FoodProviderRequester,
  setSelectedFoodProvider: React.Dispatch<React.SetStateAction<DeepPartial<FoodProvider> | null>>,
  firstItemId?: string,
  isMobile?: boolean
) => {
  if (requester && !isMobile && hasItems && firstItemId) {
    try {
      const firstItem = await requester.getById(firstItemId);

      if (firstItem) {
        setSelectedFoodProvider(firstItem);
      }
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message);
      }
    }
  }
};

export function orderServiceToNestedOrderService(
  orderService: LocalizedOrderService
): Localized<NestedOrderService> {
  return {
    [Lang.en]: {
      sys: orderService.sys,
      fields: {
        Name: {
          [Lang.en]: orderService.name[Lang.en],
          [Lang.fr]: orderService.name[Lang.fr],
        },
        Image: {
          [Lang.en]: {
            fields: {
              file: {
                [Lang.en]: {
                  url: orderService.imageUrl[Lang.en],
                },
                [Lang.fr]: {
                  url: orderService.imageUrl[Lang.fr],
                },
              },
            },
          },
        },
      },
    },
  };
}

export function unitToNestedUnit(unit: UnitType): NestedUnit {
  return {
    sys: unit.sys,
    fields: {
      WebStoreName: {
        [Lang.en]: unit.webStoreName,
        [Lang.fr]: unit.webStoreNameFrench,
      },
      Property: {
        [Lang.en]: {
          sys: {
            id: unit.property.sys?.id ?? unit.property.propertyCd,
          },
          fields: {
            WebPropertyName: {
              [Lang.en]: unit.property.webPropertyName,
            },
            PropertyCd: {
              [Lang.en]: unit.property.propertyCd,
            },
          },
        },
      },
      FNO: {
        [Lang.en]: {
          sys: {
            id: unit.fno.sys?.id ?? unit.fno.name,
          },
        },
      },
      RetailCategories: {
        [Lang.en]: [
          ...unit.retailCategoriesCollection.items.map((item) => {
            return {
              sys: item.sys,
            };
          }),
        ],
      },
      UnitCd: {
        [Lang.en]: unit.unitCd ?? '',
      },
      RetailerGroups: {
        [Lang.en]: [
          {
            sys: {
              id: unit.retailerId ?? '',
            },
          },
        ],
      },
    },
  };
}

export function propertyToNestedProperty(property: Property): NestedProperty {
  const propertyCdField = property.propertyCd
    ? {
        PropertyCd: {
          [Lang.en]: property.propertyCd,
        },
      }
    : null;

  return {
    sys: property.sys,
    fields: {
      WebPropertyName: {
        [Lang.en]: property.webPropertyName,
        [Lang.fr]: property.webPropertyNameFrench,
      },
      ...propertyCdField,
    },
  };
}

export type HandleSubmitProps = {
  requester: FoodProviderRequester;
  selectedFoodProvider: DeepPartial<FoodProvider> | null;
  orderOptionsToCreate: Partial<OrderOption>[];
  orderOptionsToDelete: Partial<OrderOption>[];
  selectedOrderOptions: OrderOption[];
  fileEnglish?: File | null;
  fileFrench?: File | null;
  saveAsDraft: boolean;
};

export async function handleSubmit({
  requester,
  selectedFoodProvider,
  orderOptionsToCreate,
  orderOptionsToDelete,
  selectedOrderOptions,
  fileEnglish,
  fileFrench,
  saveAsDraft = false,
}: HandleSubmitProps) {
  if (requester) {
    const newStatus = saveAsDraft ? Status.Draft : Status.PendingReview;

    const unitId = selectedFoodProvider?.unit?.[Lang.en]?.sys?.id;
    // Create order options (and their corresponding platform link), set them to to-be-created
    const setToBeCreatedOrderOptions: ({ id: string } | undefined)[] = await Promise.all(
      orderOptionsToCreate.map(async (orderOptionToCreate) => {
        const platformLinkUrl = orderOptionToCreate?.orderUrl?.[Lang.en]?.fields.WebLink;

        const propertyId = selectedFoodProvider?.property?.[Lang.en]?.sys?.id;
        const unitId = selectedFoodProvider?.unit?.[Lang.en]?.sys?.id;

        const orderServiceId = orderOptionToCreate.orderService?.[Lang.en]?.sys?.id;

        if (
          platformLinkUrl?.[Lang.en] &&
          propertyId &&
          unitId &&
          orderServiceId &&
          orderOptionToCreate.orderMethods
        ) {
          try {
            let platformLinkData: PlatformLinkData = {
              property: propertyId,
              unit: unitId,
              orderService: orderServiceId,
              webLink: platformLinkUrl[Lang.en],
            };

            if (platformLinkUrl?.[Lang.fr]) {
              platformLinkData = {
                ...platformLinkData,
                webLinkFrench: platformLinkUrl[Lang.fr],
              };
            }

            const newPlatformLink = await requester.createPlatformLink(platformLinkData);

            return requester.createOrderOption({
              property: propertyId,
              unit: unitId,
              orderService: orderServiceId,
              orderUrl: newPlatformLink.id,
              orderMethods: orderOptionToCreate.orderMethods,
            });
          } catch (error) {
            console.error({ message: `Error creating platform link or order option: ${error}` });
            return undefined; // Handle individual errors without crashing the whole Promise.all
          }
        } else {
          return undefined;
        }
      })
    );

    // Map over the created options to get their IDs (ignores undefined values)
    const setToBeCreatedOrderOptionIds = setToBeCreatedOrderOptions
      .filter(
        (setToBeCreatedOrderOption): setToBeCreatedOrderOption is { id: string } =>
          !!setToBeCreatedOrderOption
      ) // Type guard to filter out undefined values
      .map((setToBeCreatedOrderOption) => setToBeCreatedOrderOption.id);

    // Delete order options (and their corresponding platform link), set them to to-be-deleted
    const setToBeDeletedOrderOptions: ({ id: string } | undefined)[] = await Promise.all(
      orderOptionsToDelete.map(async (orderOptionToDelete) => {
        const platformLinkId = orderOptionToDelete?.orderUrl?.[Lang.en].sys?.id;
        const orderOptionId = orderOptionToDelete?.sys?.id;

        if (unitId && orderOptionId && platformLinkId) {
          try {
            await requester.editPlatformLink(
              platformLinkId,
              {
                unit: unitId,
              },
              NestedStatus.PendingDeletion
            );

            return requester.editOrderOption(
              orderOptionId,
              {
                unit: unitId,
              },
              NestedStatus.PendingDeletion
            );
          } catch (error) {
            console.error({
              message: `Error setting platform link or order option to be deleted: ${error}`,
            });
            return undefined; // Handle individual errors without crashing the whole Promise.all
          }
        } else {
          console.log({
            message: 'Insufficient data to delete order option ',
            data: {
              unitId,
              orderOptionId,
              platformLinkId,
            },
          });
        }
      })
    );

    // Map over the set-to-be-deleted options to get their IDs (ignores undefined values)
    const setToBeDeletedOrderOptionIds = setToBeDeletedOrderOptions
      .filter(
        (setToBeDeletedOrderOption): setToBeDeletedOrderOption is { id: string } =>
          !!setToBeDeletedOrderOption
      ) // Type guard to filter out undefined values
      .map((setToBeDeletedOrderOption) => setToBeDeletedOrderOption.id);

    // Edit order options (and their corresponding platform link), set them to to-be-updated
    const setToBeEditedOrderOptions: ({ id: string } | undefined)[] = await Promise.all(
      (selectedOrderOptions ?? []).map(async (orderOptionToEdit) => {
        const orderOptionId = orderOptionToEdit.sys?.id;
        const platformLinkUrl = orderOptionToEdit?.orderUrl?.[Lang.en]?.fields.WebLink;

        const orderServiceId = orderOptionToEdit.orderService?.[Lang.en]?.sys?.id;
        const platformLinkId = orderOptionToEdit?.orderUrl?.[Lang.en]?.sys?.id;

        if (unitId && orderOptionId && platformLinkId) {
          try {
            let platformLinkData: Partial<PlatformLinkData> = {
              unit: unitId,
            };

            if (platformLinkUrl?.[Lang.en] || platformLinkUrl?.[Lang.en] === '') {
              platformLinkData = {
                ...platformLinkData,
                webLink: platformLinkUrl[Lang.en],
              };
            }

            if (platformLinkUrl?.[Lang.fr] || platformLinkUrl?.[Lang.fr] === '') {
              platformLinkData = {
                ...platformLinkData,
                webLinkFrench: platformLinkUrl[Lang.fr],
              };
            }

            const updatedPlatformLink = await requester.editPlatformLink(
              platformLinkId,
              platformLinkData,
              NestedStatus.PendingUpdate
            );

            let orderOptionData: Partial<OrderOptionData> = {
              unit: unitId,
              orderUrl: updatedPlatformLink.id,
            };

            if (orderServiceId) {
              orderOptionData = {
                ...orderOptionData,
                orderService: orderServiceId,
              };
            }

            if (orderOptionToEdit?.orderMethods?.length > 0) {
              orderOptionData = {
                ...orderOptionData,
                orderMethods: orderOptionToEdit.orderMethods,
              };
            }

            return requester.editOrderOption(
              orderOptionId,
              orderOptionData,
              NestedStatus.PendingUpdate
            );
          } catch (error) {
            console.error('Error updating platform link or order option:', error);
            return undefined; // Handle individual errors without crashing the whole Promise.all
          }
        } else {
          console.log({
            message: 'Insufficient data to update order option',
            data: {
              unitId,
              orderOptionId,
              platformLinkId,
            },
          });
          return undefined;
        }
      })
    );

    // Map over the edited options to get their IDs (ignores undefined values)
    const setToBeEditedOrderOptionIds = setToBeEditedOrderOptions
      .filter(
        (setToBeEditedOrderOption): setToBeEditedOrderOption is { id: string } =>
          !!setToBeEditedOrderOption
      ) // Type guard to filter out undefined values
      .map((setToBeEditedOrderOption) => setToBeEditedOrderOption.id);

    const allOrderOptionIds: string[] = [
      ...setToBeEditedOrderOptionIds,
      ...setToBeCreatedOrderOptionIds,
      ...setToBeDeletedOrderOptionIds,
    ];

    let res = null;
    try {
      let foodProviderData: Partial<FoodProviderData> = {};

      const propertyId = selectedFoodProvider?.property?.[Lang.en]?.sys?.id;

      const optionalFields = {
        fileEnglish,
        fileFrench,
        menuUrl: selectedFoodProvider?.menuUrl,
        menuUrlFrench: selectedFoodProvider?.menuUrlFrench,
        reserveTableUrl: selectedFoodProvider?.reserveTableUrl,
        reserveTableUrlFrench: selectedFoodProvider?.reserveTableUrlFrench,
        orderOptions: allOrderOptionIds.length > 0 ? allOrderOptionIds : undefined,
        property: propertyId,
        unit: selectedFoodProvider?.unit?.[Lang.en]?.sys?.id,
      };

      // Add fields to foodProviderData only if they exist
      foodProviderData = {
        ...foodProviderData,
        ...Object.fromEntries(
          Object.entries(optionalFields).filter(([_, value]) => value !== undefined)
        ),
      };

      if (selectedFoodProvider?.sys?.id) {
        // Edit existing ID
        res = await requester.edit(
          selectedFoodProvider.sys.id,
          omitBy(foodProviderData, isUndefined),
          newStatus
        );
      } else if (
        !saveAsDraft &&
        (!fileEnglish || allOrderOptionIds?.length <= 0 || !propertyId || !unitId)
      ) {
        console.error({
          message: 'No sufficient fields to create food provider',
          data: {
            saveAsDraft,
            fileEnglish: !!fileEnglish,
            orderOptionIdsTotal: allOrderOptionIds?.length,
            propertyId,
            unitId,
          },
        });
      } else {
        // Create new
        res = await requester.create(omitBy(foodProviderData, isUndefined), newStatus);
      }
    } catch (e) {
      toast.error(`Error when editing/creating food provider: ${e}`);
    }

    return res;
  }
}
