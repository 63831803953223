import type { FoodProvider, FoodProviderData, FoodProviderBase, EntryCollection } from 'types';
import Status, { NestedStatus } from 'constants/Status';
import Requester, { GetItemsParams } from '../baseRequester';
import Property from 'types/property.type';
import { LocalizedOrderService } from 'types/orderService.type';
import OrderMethod from 'types/orderMethod.type';
import Floor from 'types/floor.type';
import { OrderOption, OrderOptionData } from 'types/orderOption.type';
import UnitType from 'types/unit.type';
import { toFormData } from 'helpers/form';
import { PlatformLinkData } from 'types/platformLink.type';

const foodProviderPath = '/foodProvider';
const orderOptionPath = '/orderOption';
const orderServicePath = '/orderService';
const orderMethodPath = '/orderMethod';
const floorPath = '/floor';
const propertyPath = '/property';
const unitPath = '/unit';
const platformLinkPath = '/platformLink';
class FoodProviderRequester extends Requester<FoodProvider, FoodProviderBase, FoodProviderData> {
  constructor(apiToken: string) {
    super({ baseURL: '/api', apiToken });
  }
  // Specifies only Draft and Pending status for this function
  async create(data: Partial<FoodProviderData>, status: Status.Draft | Status.PendingReview) {
    return super.create(data, status, foodProviderPath);
  }

  // Specifies only Draft and Pending status for this function
  async edit(
    id: string,
    data: Partial<FoodProviderData>,
    status: Status.Draft | Status.PendingReview
  ) {
    return super.edit(id, data, status, foodProviderPath);
  }

  async getItems({ search = '', ...otherParams }: Omit<GetItemsParams, 'path'>) {
    return super.getItems({ search, path: foodProviderPath, ...otherParams });
  }

  async getById(id: string) {
    return super.getById(id, foodProviderPath);
  }

  async discard(id: string) {
    return super.discard(id, foodProviderPath);
  }

  async getOrderOptionById(id: string) {
    const res = await this.axios.get<OrderOption>(`${orderOptionPath}/${id}`);
    return res.data;
  }

  async getOrderServices() {
    const res = await this.axios.get<EntryCollection<LocalizedOrderService>>(
      `${orderServicePath}/`,
      {
        params: {
          skip: 0,
          limit: 30,
        },
      }
    );
    return res.data;
  }

  async getOrderMethods() {
    const res = await this.axios.get<EntryCollection<OrderMethod>>(`${orderMethodPath}/`, {
      params: {
        skip: 0,
        limit: 30,
      },
    });
    return res.data;
  }

  async getFloorById(id: string) {
    const res = await this.axios.get<Floor>(`${floorPath}/${id}`);
    return res.data;
  }

  async getProperties(restrictToUserUnits = false, ids: string[] = []) {
    let propertyParams: { restrictToUserUnits: boolean; ids?: string } = {
      restrictToUserUnits,
    };

    if (ids.length > 0) {
      propertyParams = {
        ...propertyParams,
        ids: ids.join(','),
      };
    }

    const res = await this.axios.get<Property[]>(`${propertyPath}/`, {
      params: propertyParams,
    });

    return res.data;
  }

  async getUnits(restrictToAvailableFoodProviderUnits = true) {
    const res = await this.axios.get<UnitType[]>(`${unitPath}/`, {
      params: {
        restrictToAvailableFoodProviderUnits,
      },
    });

    return res.data;
  }

  async createPlatformLink(data: PlatformLinkData) {
    const form = toFormData({
      ...data,
      status: NestedStatus.PendingCreation,
    });
    const res = await this.axios.post<{ id: string }>(`${platformLinkPath}/`, form);
    return res.data;
  }

  async editPlatformLink(
    id: string,
    data: Partial<PlatformLinkData>,
    status: NestedStatus.PendingCreation | NestedStatus.PendingDeletion | NestedStatus.PendingUpdate
  ) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.put<{ id: string }>(`${platformLinkPath}/${id}`, form);
    return res.data;
  }

  async createOrderOption(data: OrderOptionData) {
    const form = toFormData({
      ...data,
      status: NestedStatus.PendingCreation as string,
    });
    const res = await this.axios.post<{ id: string }>(`${orderOptionPath}/`, form);
    return res.data;
  }

  async editOrderOption(
    id: string,
    data: Partial<OrderOptionData>,
    status: NestedStatus.PendingCreation | NestedStatus.PendingDeletion | NestedStatus.PendingUpdate
  ) {
    const form = toFormData({ ...data, status });
    const res = await this.axios.put<{ id: string }>(`${orderOptionPath}/${id}`, form);
    return res.data;
  }
}

export default FoodProviderRequester;
